import { createSlice } from '@reduxjs/toolkit'

/**
 * Store for the app state such as modals and other global state
 */
export const appState = createSlice({
  name: 'app',
  initialState: {
    createOrJoinClassroomOpen: false,
    createActivityOpen: false,
    productsModalOpen: false,
    createFolderModalOpen: false,
    createSpaceModalOpen: false,
    createActivityOptionsOpen: false,
    setPreviewDrawerOpen: false,
    selectedSet: null,
    ltiRosterImportOpen: false,
  },
  reducers: {
    setCreateOrJoinClassroomOpen: (state, action) => {
      state.createOrJoinClassroomOpen = action.payload
    },
    setCreateActivityOpen: (state, action) => {
      state.createActivityOpen = action.payload
    },
    setProductsModalOpen: (state, action) => {
      state.productsModalOpen = action.payload
    },
    setCreateFolderModalOpen: (state, action) => {
      state.createFolderModalOpen = action.payload
    },
    setCreateActivityOptionsOpen: (state, action) => {
      state.createActivityOptionsOpen = action.payload
    },
    setSetPreviewDrawerOpen: (state, action) => {
      state.setPreviewDrawerOpen = action.payload.open
      state.selectedSet = action.payload.set || null
    },
    setSelectedSet: (state, action) => {
      state.selectedSet = action.payload
    },
    setLtiRosterImportModalOpen: (state, action) => {
      state.ltiRosterImportOpen = action.payload
    },
    setCreateSpaceModalOpen: (state, action) => {
      state.createSpaceModalOpen = action.payload
    },
  },
})

export const {
  setCreateOrJoinClassroomOpen,
  setCreateActivityOpen,
  setProductsModalOpen,
  setCreateFolderModalOpen,
  setCreateActivityOptionsOpen,
  setSetPreviewDrawerOpen,
  setSelectedSet,
  setLtiRosterImportModalOpen,
  setCreateSpaceModalOpen,
} = appState.actions

export default appState.reducer
